<template>
    <zw-sidebar @shown="shown">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <b-form-group>
                            <template v-slot:label>
                                {{ $t(labelPrefix + '.upload_file') }}
                            </template>
                            <b-form-file @change="fileChanged" accept=".txt"></b-form-file>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'get-amazon-products-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: false,
            payload: {},
            callback: null,
            form: {},
            labelPrefix: 'common.label.get_amazon_products',
            response: null,
        }
    },
    mounted() {
    },
    computed: {},
    methods: {
        shown() {
            this.loading = false
        },
        fileChanged(event) {
            this.loading = true
            this.form.uploadedFile = event.target.files[0]
            let formData = new FormData();
            formData.append('file', this.form.uploadedFile);
            formData.append('sourceId', this.payload.sourceId);
            formData.append('storeId', this.payload.storeId);

            axios.post(window.apiUrl + '/multi-channel/get-amazon-products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                'skip_loading': true,
            })
                .then(result => {
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                    this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                })
        },
    }
}
</script>